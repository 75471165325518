import React, { createContext, useState, useContext } from 'react';
import { jwtDecode } from 'jwt-decode'; // Correct import using named import
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);

  const register = (jwtToken) => {
    try {
      const decodedToken = jwtDecode(jwtToken); 
      setToken(jwtToken);
      setIsAuthenticated(true);
      localStorage.setItem('token', JSON.stringify(decodedToken));
      const userId = decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
      localStorage.setItem('userId',userId);
    } catch (error) {
      setIsAuthenticated(false);
      setToken(null);
    }
  };

  const logout = () => {
    setToken(null);
    setIsAuthenticated(false);
    localStorage.removeItem('token');
  };

  const getToken = () => {
    return token || localStorage.getItem('token');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, register, logout, getToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
