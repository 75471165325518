import React, { useState, useEffect } from 'react';
import './BiauMainFrame.css'; 

function BiauMainFrame() {
  const [accounts, setAccounts] = useState([]);
  const [email, setNewEmail] = useState('');
  const [userId, setUserId] = useState(localStorage.getItem('userId') || '');
  const [organizations, setOrganizations] = useState([]);
  const [orgName, setOrgName] = useState('');
  const [shortHand, setShortHand] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [selectedVerticals, setSelectedVerticals] = useState([]);
  const [paymentAmount, setPaymentAmount] = useState('');
  const [paymentPeriodicity, setPaymentPeriodicity] = useState('Yearly');
  const [verticals, setVerticals] = useState([]);
  const [newVertical, setNewVertical] = useState(''); 
  const [activeTab, setActiveTab] = useState('users');
  const organisationId = "8c260c05-7314-40c8-83a1-a759b5d76b9c"; 
  const [logoUrl, setLogoUrl] = useState(''); // State for the logo URL
  const [communicationEmail, setCommunicationEmail] = useState(''); // State for the logo URL

  const handleAddVertical = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('https://sll55ktwk7exsfuk4wgtwvffpq0kyzco.lambda-url.eu-west-2.on.aws/', {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify({
          VerticalName: newVertical,
        }),
      });

      const data = await response.json();

      if (response.status === 200 && data) {
        setVerticals(prevVerticals => [...prevVerticals, { verticalId: data, verticalName: newVertical }]);
        setNewVertical(''); // Clear input after submission
        alert(`Vertical created successfully with id ${data}`);
      } else {
        throw new Error('Invalid or incomplete response from the server.');
      }
    } catch (e) {
      console.error('Error during vertical creation:', e);
      alert('Vertical creation failed. Please try again.');
    }
  };

  const handleRegisterUser = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('https://dr7pmfybzu7t77k2gr54g3exbu0mhmyu.lambda-url.eu-west-2.on.aws/', {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify({
          Email: email,
          OrganisationId: organisationId
        }),
      });

      const data = await response.json();

      if (response.status === 200 && data) {
        setAccounts(prevAccounts => [...prevAccounts, { id: data, email: email }]);
        setNewEmail(''); // Clear input after submission
        alert(`Registration successful with UserId: ${data}`);
      } else {
        throw new Error('Invalid or incomplete response from the server.');
      }
    } catch (e) {
      console.error('Error during registration:', e);
      alert('Registration failed. Please try again.');
    }
  };

  const handleRegisterOrganization = async (event) => {
    event.preventDefault();
    const payload = {
      OrganisationName: orgName,
      AdminEmails: [adminEmail],
      ShortHand: shortHand,
      Verticals: selectedVerticals,
      PaymentInfo: {
        Amount: parseInt(paymentAmount),
        Periodicity: paymentPeriodicity
      },
      LogoUrl:logoUrl,
      CommunicationEmail:communicationEmail
        };

    try {
      const response = await fetch('https://257tq77upsiemrwegkzmnddotm0kceqh.lambda-url.eu-west-2.on.aws/', {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.status === 200 && data) {
        setOrganizations(prevOrganizations => [...prevOrganizations, {  
          OrganisationId: data,  
          ShortHand: shortHand,  
          VerticalName: orgName,        
          AdminEmail: adminEmail,   
          PaymentInfo: {
            Amount: paymentAmount,
            Periodicity: paymentPeriodicity
          },
          Verticals: selectedVerticals,
          logoUrl:logoUrl
        }]);
        
        setOrgName(''); 
        setAdminEmail('');
        setSelectedVerticals([]);
        setPaymentAmount('');
        setPaymentPeriodicity('Yearly');
        setLogoUrl('');
        setCommunicationEmail('');
        alert(`Organization added with ID: ${data}`);
      } else {
        throw new Error('Invalid or incomplete response from the server.');
      }
    } catch (e) {
      console.error('Error during organization registration:', e);
      alert('Failed to add organization. Please try again.');
    }
  };

  const handleVerticalSelection = (event) => {
    const value = event.target.value;
    setSelectedVerticals((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((v) => v !== value)
        : [...prevSelected, value]
    );
  };

  const handlePaymentPeriodicityToggle = () => {
    setPaymentPeriodicity((prevPeriodicity) =>
      prevPeriodicity === 'Yearly' ? 'Monthly' : 'Yearly'
    );
  };

  useEffect(() => {
    setUserId(localStorage.getItem('userId'))
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://id6s3zedrulukuq25xmkq6765y0urhkw.lambda-url.eu-west-2.on.aws/', {
          method: 'POST',
          headers: {
            'Content-Type': 'text/plain',
          },
          body: JSON.stringify({ OrganisationId: organisationId,UserId:userId }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (data) {
          const fetchedAccounts = data.map((user) => ({
            id: user.UserId,
            email: user.Email
          }));
          setAccounts(fetchedAccounts);
        } else {
          console.error('No data received from the server');
        }
      } catch (e) {
        console.error('Error fetching users:', e);
      }
    };

    const fetchOrganizations = async () => {
      try {
        const response = await fetch('https://3ytoar46gmn43betgdjdurkwpu0gyazc.lambda-url.eu-west-2.on.aws/', {
          method: 'POST',
          headers: {
            'Content-Type': 'text/plain',
          },
          body:  JSON.stringify({UserId:userId }) ,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (data) {
          const fetchedOrganizations = data.map((org) => ({
            VerticalName: org.VerticalName,
            AdminEmail: org.AdminEmail,
            OrganisationId: org.OrganisationId,
            PaymentInfo: {
              Amount: org.PaymentInfo.Amount,
              Periodicity: org.PaymentInfo.Periodicity
            },
            Verticals: org.Verticals,
            logoUrl: org.LogoUrl
          }));

          setOrganizations(fetchedOrganizations);
        } else {
          console.error('No data received from the server');
        }
      } catch (e) {
        console.error('Error fetching organizations:', e);
      }
    };

    const fetchVerticals = async () => {
      try {
        const response = await fetch('https://q4acvrem65iopz255fgpik5xmu0uldkp.lambda-url.eu-west-2.on.aws/', {
          method: 'POST',
          headers: {
            'Content-Type': 'text/plain',
          },
          body: JSON.stringify({ UserId: userId }),
        });

        const data = await response.json();

        if (data) {
          const fetchedVerticals = data.map((vertical) => ({
            verticalId: vertical.VerticalId,
            verticalName: vertical.VerticalName
          }));
          setVerticals(fetchedVerticals);
          console.log('Fetched verticals:', fetchedVerticals);
        } else {
          console.error('No verticals received from the server');
        }
      } catch (e) {
        console.error('Error fetching verticals:', e);
      }
    };

    fetchUsers();
    fetchOrganizations();
    fetchVerticals();
  }, [organisationId]);

  return (
    <div className="ErpSystem">
      <header className="ErpSystem-header">
        <h1>Biau Company Management</h1>
        <div className="tab">
          <button className={`tablinks ${activeTab === 'users' ? 'active' : ''}`} onClick={() => setActiveTab('users')}>Users</button>
          <button className={`tablinks ${activeTab === 'organizations' ? 'active' : ''}`} onClick={() => setActiveTab('organizations')}>Organizations</button>
          <button className={`tablinks ${activeTab === 'verticals' ? 'active' : ''}`} onClick={() => setActiveTab('verticals')}>Verticals</button>
        </div>
      </header>
      <div className="tabcontent">
        {activeTab === 'users' && (
          <div>
            <h2>Biau Registered Accounts</h2>
            <div className="account-list">
              {accounts.map(account => (
                <div key={account.id} className="account-item">
                  {account.email}
                </div>
              ))}
            </div>
            <form onSubmit={handleRegisterUser}>
              <input
                type="email"
                placeholder="Email address"
                value={email}
                onChange={(e) => setNewEmail(e.target.value)}
                required
              />
              <button type="submit">Add User</button>
            </form>
          </div>
        )}
        {activeTab === 'organizations' && (
          <div>
            <h2>Organizations</h2>
            <div className="organization-list">
              {organizations.map((org) => (
                <div key={org.OrganisationId} className="organization-item">
                  <h3>{org.VerticalName}</h3>
                  <p><strong>Organisation ID:</strong> {org.OrganisationId}</p>
                  <p><strong>ShortHand:</strong> {org.ShortHand}</p>
                  <p><strong>Admin Email:</strong> {org.AdminEmail}</p>
                  <p><strong>Payment Info:</strong> {org.PaymentInfo.Amount} {org.PaymentInfo.Periodicity}</p>
                  <p><strong>Verticals:</strong> {org.Verticals.join(', ')}</p>
                </div>
              ))}
            </div>
            <form onSubmit={handleRegisterOrganization}>
              <input
                type="text"
                placeholder="Organization name"
                value={orgName}
                onChange={(e) => setOrgName(e.target.value)}
                required
              />
                <input
                type="text"
                placeholder="Short Hand"
                value={shortHand}
                onChange={(e) => setShortHand(e.target.value)}
                required
              />
              <input
                type="email"
                placeholder="Admin email"
                value={adminEmail}
                onChange={(e) => setAdminEmail(e.target.value)}
                required
              />
              <div>
                <label>Select Verticals:</label>
                {verticals.map((vertical) => (
                  <div key={vertical.verticalId}>
                    <input
                      type="checkbox"
                      value={vertical.verticalName}
                      checked={selectedVerticals.includes(vertical.verticalName)}
                      onChange={handleVerticalSelection}
                    />
                    <label>{vertical.verticalName}</label>
                  </div>
                ))}
              </div>
              <input
                type="number"
                placeholder="Payment amount"
                value={paymentAmount}
                onChange={(e) => setPaymentAmount(e.target.value)}
                required
              />
              <div>
                <label>Payment Periodicity:</label>
                <button type="button" onClick={handlePaymentPeriodicityToggle}>
                  {paymentPeriodicity}
                </button>
              </div>
              <input
                type="text"
                placeholder="logo url"
                value={logoUrl}
                onChange={(e) => setLogoUrl(e.target.value)}
                required
              />
                            <input
                type="CommunicationEmail"
                placeholder="communication email"
                value={communicationEmail}
                onChange={(e) => setCommunicationEmail(e.target.value)}
                required
              />
              <button type="submit">Add Organization</button>
            </form>
            
          </div>
        )}
        {activeTab === 'verticals' && (
         <div>
         <h2>Verticals</h2>
         <div className="vertical-list">
           {verticals.map((vertical) => (
             <div key={vertical.verticalId} className="vertical-item">
               {vertical.verticalName}
             </div>
           ))}
         </div>
         <form onSubmit={handleAddVertical}>
           <input
             type="text"
             placeholder="Add a new vertical"
             value={newVertical}
             onChange={(e) => setNewVertical(e.target.value)}
             required
           />
           <button type="submit">Add Vertical</button>
         </form>
       </div>
        )}
      </div>
    </div>
  );
}

export default BiauMainFrame;
