import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import { QRCode } from 'react-qrcode';
import styled from 'styled-components';
import HelpModal from './HelpModal';
import { useAuth } from '../Auth/AuthContext';
import urlJoin from 'url-join';

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Hook to get location object
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [verificationToken, setVerificationToken] = useState('');
  const [wss, setWss] = useState(null);
  const { register } = useAuth();
  const savedPath = localStorage.getItem('redirectPath') || location.pathname;
  const navigateTo = localStorage.getItem('redirectPath');
  const pathSegments = savedPath.split('/');
  
 useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await fetch('https://qc4dgc3mycht57aaq7s4swqlue0lzmcp.lambda-url.eu-west-2.on.aws/', {
          method: 'POST',
        });
        
        const data = await response.json();
        setVerificationToken(data.Token);
        const socket = new WebSocket(`wss:AuthenticationWss.biau.app:8081?token=${data.Token}`);
        setWss(socket);

        socket.onmessage = (event) => {
          const message = JSON.parse(event.data);

          register(message.jwtToken);

          if (message.action === 'verify' && message.status === 'success') {
            navigate(urlJoin('/', navigateTo));
          }
        };

        socket.onerror = (error) => {
          console.error('WebSocket error:', error);
        };

        socket.onclose = () => {
          console.log('WebSocket connection closed');
        };

      } catch (error) {
        console.error('Error fetching token:', error);
      }
    };

    fetchToken();

    // Cleanup function to close WebSocket connection
    return () => {
      if (wss) {
        wss.close();
      }
    };
  }, []);

  const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: black;
    position: relative;
    overflow: hidden;
  `;

  const PurpleSquare = styled.div`
    width: 300px;
    height: 300px;
    background-color: #012345;
    position: absolute;
    box-shadow: 0 0 50px rgba(72, 0, 255, 0.5);
    z-index: 1;
    left: calc(50% - 150px); /* Center horizontally */
    top: calc(50% - 150px);  /* Center vertically */
  `;

  
    // Determine request level based on path
    const requestLevel = savedPath.toLowerCase().includes('organisations' || 'erp') ? 'admin' : 'noAccess';
    const lastSegment = pathSegments[pathSegments.length - 1] || 'defaultOrg';
    localStorage.setItem('orgName', lastSegment); // Store it in local storage
  
    const qrCodeData = JSON.stringify({
      organisation: lastSegment,
      token: verificationToken,
      requestLevel: requestLevel,
    });
  
  
  return (
    <Container>
      <PurpleSquare />
      <h2 style={{ color: 'white', zIndex: 2 }}>BIAU geeft geen toegang</h2>
      <div style={{ zIndex: 1 }}>
        <QRCode
          value={qrCodeData}
          size={256}
          bgColor="#ffffff"
          fgColor="#000000"
        />
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        color: 'white',
        textDecoration: 'underline',
        cursor: 'pointer',
        zIndex: 2,
      }}>
        <p
          style={{
            color: 'white',
            textDecoration: 'underline',
            cursor: 'pointer',
            zIndex: 2,
            padding: 5
          }}
          onClick={() => navigate('/register')}
        >
          Skip BIAU?
        </p>

        <p 
          style={{
            color: 'white',
            textDecoration: 'underline',
            cursor: 'pointer',
            zIndex: 2,
            padding: 5
          }}
          onClick={() => setIsHelpModalOpen(true)}
        >
          Need Help?
        </p>
      </div>
      <HelpModal
        isOpen={isHelpModalOpen}
        onRequestClose={() => setIsHelpModalOpen(false)}
        style={{ zIndex: 3 }} // Adjust the z-index of the modal
      />
    </Container>
  );
};

export default Home;