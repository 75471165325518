import React, { useState, useEffect } from 'react';
import './Organisations.css'; // Include the styling file
import Papa from 'papaparse';

function Organisations() {
  const [verticalUsers, setVerticalUsers] = useState([]); 
  const [currentVerticalUsers, setCurrentVerticalUsers] = useState([]); 
  const [parsedData, setParsedData] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [email, setNewEmail] = useState('');
  const [gameDescription, setGameDescription] = useState('');
  const [biauLocation, setBiauLocation] = useState('');
  const [accreditations, setAccreditations] = useState([]);
  const [orgName, setOrgName] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [backgroundUrl, setBackgroundUrl] = useState('');
  const [verticalDate, setVerticalDate] = useState('');
  const [verticalLocation, setVerticalLocation] = useState('');
  const [shorthand, setShortHand] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [communicationEmail, setCommunicationEmail] = useState('');
  const [biauEmail, setBiauEmail] = useState('');
  const [selectedVerticals, setSelectedVerticals] = useState([]);
  const [endDate, setEndDate] = useState('');
  const [paymentPeriodicity, setPaymentPeriodicity] = useState('Yearly');
  const [verticals, setVerticals] = useState([]);
  const [newVertical, setNewVertical] = useState(''); 
  const [activeTab, setActiveTab] = useState('');
  const [biauType, setBiauType] = useState('');
  const [biauDate, setBiauDate] = useState('');
  const [paymentInfo, setPaymentInfo] = useState({ amount: '', periodicity: '' }); // Store   
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [color, setColor] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [currentPage, setCurrentPage] = useState(1); // New state for current page
  const [totalPages, setTotalPages] = useState(0); // State for total pages
  const [usersPerPage, setUsersPerPage] = useState(10); // State for number of users per page, default 10
  const [toggleForm, setToggleForm] = useState(false); // State for number of users per page, default 10
  const [toggleFormType, setToggleFormType] = useState(true); // State for number of users per page, default 10
      // Define the required fields for VerticalRequest, matching the properties of the class
      const requiredFields = [
        "Email",
        "Title",
        "Type",
        "ShortHand",
        "Description",
        "Color",
        "Date",
        "Location",
        "LogoUrl",
        "BackgroundUrl",
      ];
      
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    Papa.parse(file, {
      header: true,
      complete: function(results) {
        console.log("Parsed data:", results);
    
        const validationResult = validateCSVData(results.data);
    
        if (!validationResult.isValid) {
          const errorMessages = validationResult.errors.map(
            error => `Row ${error.row} is missing the following fields: ${error.missingFields.join(', ')}`
          );
          alert(`CSV validation failed:\n${errorMessages.join('\n')}`);
          return;
        }
              
      // Filter out completely empty rows before mapping the data
      const filteredData = results.data.filter(entry => {
        // Check if all fields in a row are empty
        const isRowEmpty = requiredFields.every(field => !entry[field] || entry[field].trim() === '');
        return !isRowEmpty; // Only keep rows that are not empty
      });

      // Map parsed CSV data to the VerticalRequest structure
      const mappedData = filteredData.map(entry => ({
        VerticalName: capitalizeFirstLetter(activeTab),
        ShortHand: shorthand.toUpperCase(),
        BiauEmail: entry.Email,
        OrganisationName: orgName,
        VerticalTitle: entry.Title,
        VerticalType: entry.Type,
        VerticalDescription: entry.Description,
        VerticalColor: entry.Color,
        VerticalDate: entry.Date,
        VerticalLocation: entry.Location,
        LogoUrl: entry.LogoUrl,
        BackgroundUrl: entry.BackgroundUrl,
        CommunicationEmail: communicationEmail
      }));


      // Continue with the rest of your logic (e.g., setting state, handling bulk upload, etc.)
      setParsedData(mappedData);
      }
    });
  };

  const handleUsersPerPageChange = (event) => {
    setUsersPerPage(Number(event.target.value)); // Update usersPerPage based on dropdown selection
  };

  const validateCSVData = (data) => {
  
    const errors = []; // To collect error information
  
    // Filter out completely empty rows
    const validData = data.filter(entry => {
      const isRowEmpty = requiredFields.every(field => !entry[field] || entry[field].trim() === '');
      return !isRowEmpty;
    });
  
    // Validate each entry for missing fields and collect errors
    validData.forEach((entry, index) => {
      const missingFields = requiredFields.filter(field => !(field in entry) || entry[field].trim() === '');
  
      // If there are missing fields, log the row and the missing fields
      if (missingFields.length > 0) {
        errors.push({
          row: index + 1, // CSV rows are usually 1-based, not 0-based
          missingFields: missingFields
        });
      }
    });
  
    // If there are errors, return false and log the error details
    if (errors.length > 0) {
      console.log("Validation errors:", errors);
      return { isValid: false, errors: errors };
    }
  
    // Return true if all rows are valid
    return { isValid: true };
  };

  const handleBulkUpload = async () => {
    try {
      const response = await fetch('https://dmzaxtvo7ubm2vgant5e4f4i740frwjy.lambda-url.eu-west-2.on.aws/', {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
      body: JSON.stringify(parsedData)
      });
      console.error(parsedData)
      if (response.ok) {
        const result = await response.json();
        addUsersToView(parsedData)
        console.log('Bulk upload successful:', result);
      } else {

        addUsersToView(parsedData)

        throw new Error('Bulk upload failed');
      }
    } catch (error) {
      addUsersToView(parsedData)
      console.error('Failed to bulk upload:', error);
    }
  };

  const addUsersToView = (parsedData) => {
    // Convert parsedData to DynamoDB-like document format
    const dynamoUsers = parsedData.map(user => ({
      BiauEmail: { S: user.BiauEmail },
      Title: { S: user.VerticalTitle },
      Type: { S: user.VerticalType },
      Description: { S: user.VerticalDescription },
      Color: { S: user.VerticalColor },
      Date: { S: user.VerticalDate },
      Location: { S: user.VerticalLocation }
    }));

    
    const currentTab = tabsConfig[activeTab];
    // Find the correct vertical by matching the current tab name
    const matchedVertical = verticalUsers.find(item => item.vertical === currentTab.name);

    // Add the mapped user to the correct vertical tab
    if (matchedVertical) {
      for (let index = 0; index < dynamoUsers.length; index++) {
        const element = dynamoUsers[index];
        matchedVertical.users.Items.push(element);
      }
    } else {
      console.error('Vertical not found for the current tab');
    }
    // Assume verticalUsers is structured to hold users for the current tab (subscriptions, tickets, etc.)
    // Find the current vertical's users and append the new ones
    setVerticalUsers(prevVerticalUsers => {

      // If the vertical for the current tab already exists, append to it
      if (prevVerticalUsers[currentTab.index]) {
        return prevVerticalUsers.map((vertical, index) => {
          if (index === currentTab.index) {
            return {
              ...vertical,
              users: {
                ...vertical.users,
                Items: [...vertical.users.Items, ...dynamoUsers]  // Append new users
              }
            };
          }
          return vertical;
        });
      } else {
        // If the vertical doesn't exist, create a new entry
        return [
          ...prevVerticalUsers,
          {
            vertical: activeTab,
            users: { Items: dynamoUsers }
          }
        ];
      }
    });
  };

  const paginate = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return; // Boundary check
    setCurrentPage(pageNumber);
  };

  const toggleAddUsers = async () =>{
    setToggleForm(!toggleForm)
  }

  const handleFormTypeToggle = async () =>{
    setToggleFormType(!toggleFormType)
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const saveVertical = async (event) => {
    event.preventDefault();
    console.error(activeTab,shorthand,email,orgName,title,biauType,description,color,verticalDate,verticalLocation)
    if (!activeTab || !shorthand || !email || !orgName || !title || !biauType || !description || !color || !verticalDate || !verticalLocation) {
      alert("Please fill in all required fields before submitting.");
      return; // Stop the function if any required field is missing
    }
  
    const payload = {
      VerticalName: capitalizeFirstLetter(activeTab),
      ShortHand: shorthand.toUpperCase(),
      BiauEmail: email,
      OrganisationName: orgName,
      VerticalTitle: title,
      VerticalType: biauType,
      VerticalDescription: description,
      VerticalColor: color,
      VerticalDate: verticalDate,
      VerticalLocation: verticalLocation,
      LogoUrl:logoUrl,
      CommunicationEmail: communicationEmail
    };
  
    const url = 'https://ihdcf3u4ucgd7aighpzsr3chdq0jcbsa.lambda-url.eu-west-2.on.aws/';
    const requestData = {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
      },
      body: JSON.stringify(payload)
    };
  
    try {
      const response = await fetch(url, requestData);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseData = await response.json();

      const parsedData = JSON.parse(responseData.Message); // Parse the stringified message
      const user = parsedData.user;
 
      const currentTab = tabsConfig[activeTab];
      // Find the correct vertical by matching the current tab name
      const matchedVertical = verticalUsers.find(item => item.vertical === currentTab.name);

      // Add the mapped user to the correct vertical tab
      if (matchedVertical) {
        // Assuming users.Items is an array
        matchedVertical.users.Items.push(user);
        
        // Trigger form toggle
        setToggleForm(!toggleForm);
      } else {
        // Log an error if the vertical is not found for the current tab
        console.error('Vertical not found for the current tab');
      }
        
      
    } catch (error) {
      console.error('Error during vertical creation:', error);
      alert('Failed to create vertical. Please try again.');
    }
  };
  
  const Pagination = ({ paginate, totalPages, currentPage }) => {
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
    return (
      <div className="pagination" style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
        {/* Dropdown to select the number of users per page */}
        <div>
          <label> users per page</label>
          <select value={usersPerPage} onChange={handleUsersPerPageChange}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
        </div>
        
        {/* Pagination buttons */}
        <div>
          <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </button>
          {pageNumbers.map(number => (
            <button
              key={number}
              onClick={() => paginate(number)}
              className={number === currentPage ? 'active' : ''}
            >
              {number}
            </button>
          ))}
          <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
    );
  };

  const tabsConfig = {
    subscriptions: {
      name: "Subscriptions",
      index: 2,
      headers: ["Email", "Title", "Type", "Description", "Color", "Date", "Location"],
      formFields: [
        { 
          type: 'email', 
          placeholder: 'Email address', 
          value: email, 
          onChange: (e) => setNewEmail(e.target.value), 
          required: true 
        },
        { 
          type: 'text', 
          placeholder: 'Title', 
          value: title, 
          onChange: (e) => setTitle(e.target.value), 
          required: true 
        },
        { 
          type: 'select', 
          placeholder: 'biau Type', 
          value: biauType, 
          onChange: (e) => setBiauType(e.target.value), 
          required: true, 
          options: ['Waterpolo', 'Synchroonzwemmen', 'Fit&Fun', 'Vrijwilligers', 'Sponsor', 'Steunend lid'] 
        },
        { 
          type: 'text', 
          placeholder: 'Description', 
          value: description, 
          onChange: (e) => setDescription(e.target.value), 
          required: true 
        },
        { 
          type: 'color', 
          placeholder: 'Color', 
          value: color, 
          onChange: (e) => setColor(e.target.value), 
          required: true 
        },
        { 
          type: 'datetime-local', 
          placeholder: 'Event Date & Time', 
          value: verticalDate, 
          onChange: (e) => setVerticalDate(e.target.value), 
          required: true 
        },
        { 
          type: 'text', 
          placeholder: 'Event Location', 
          value: verticalLocation, 
          onChange: (e) => setVerticalLocation(e.target.value), 
          required: true 
        },
        { 
          type: 'text', 
          placeholder: 'Logo Url ', 
          value: logoUrl, 
          onChange: (e) => setLogoUrl(e.target.value), 
          required: true 
        },

        { 
          type: 'text', 
          placeholder: 'Background Url ', 
          value: backgroundUrl, 
          onChange: (e) => setLogoUrl(e.target.value), 
          required: true 
        },
      ],
    },
  
    tickets: {
      index: 3,
      name: "Tickets",

      headers: ["Email", "Title", "Location", "Description", "Color", "Date", "Location"],
      formFields: [
        { 
          type: 'email', 
          placeholder: 'Email address', 
          value: email, 
          onChange: (e) => setNewEmail(e.target.value), 
          required: true 
        },
        { 
          type: 'text', 
          placeholder: 'Ticket Title', 
          value: title, 
          onChange: (e) => setTitle(e.target.value), 
          required: true 
        },
        { 
          type: 'select', 
          placeholder: 'Area', 
          value: biauLocation, 
          onChange: (e) => setBiauLocation(e.target.value), 
          required: true, 
          options: ['A', 'B', 'C', 'Standing', 'Special'] 
        },
        { 
          type: 'text', 
          placeholder: 'Game Description', 
          value: description, 
          onChange: (e) => setDescription(e.target.value), 
          required: true 
        },
        { 
          type: 'color', 
          placeholder: 'Color', 
          value: color, 
          onChange: (e) => setColor(e.target.value), 
          required: true 
        },
        { 
          type: 'datetime-local', 
          placeholder: 'Event Date & Time', 
          value: verticalDate, 
          onChange: (e) => setVerticalDate(e.target.value), 
          required: true 
        },
        { 
          type: 'text', 
          placeholder: 'Event Location', 
          value: verticalLocation, 
          onChange: (e) => setVerticalLocation(e.target.value), 
          required: true 
        },

        { 
          type: 'text', 
          placeholder: 'Logo Url ', 
          value: logoUrl, 
          onChange: (e) => setLogoUrl(e.target.value), 
          required: true 
        },


        { 
          type: 'text', 
          placeholder: 'Background Url ', 
          value: backgroundUrl, 
          onChange: (e) => setLogoUrl(e.target.value), 
          required: true 
        },
      ],
    },
  
    accreditations: {
      index: 0,
      name: "Accreditations",
      headers: ["Email", "Title", "Type", "Description", "Color", "Date", "Location"],
      formFields: [
        { 
          type: 'email', 
          placeholder: 'Email address', 
          value: email, 
          onChange: (e) => setNewEmail(e.target.value), 
          required: true 
        },
        { 
          type: 'text', 
          placeholder: 'Accredited Title', 
          value: title, 
          onChange: (e) => setTitle(e.target.value), 
          required: true 
        },
        { 
          type: 'select', 
          placeholder: 'Type', 
          value: biauType, 
          onChange: (e) => setBiauType(e.target.value), 
          required: true, 
          options: ['referee', 'player', 'coach', 'medic'] 
        },
        { 
          type: 'text', 
          placeholder: 'Description', 
          value: description, 
          onChange: (e) => setDescription(e.target.value), 
          required: true 
        },
        { 
          type: 'color', 
          placeholder: 'Color', 
          value: color, 
          onChange: (e) => setColor(e.target.value), 
          required: true 
        },
        { 
          type: 'datetime-local', 
          placeholder: 'Event Date & Time', 
          value: verticalDate, 
          onChange: (e) => setVerticalDate(e.target.value), 
          required: true 
        },
        { 
          type: 'text', 
          placeholder: 'Event Location', 
          value: verticalLocation, 
          onChange: (e) => setVerticalLocation(e.target.value), 
          required: true 
        },
        { 
          type: 'text', 
          placeholder: 'Logo Url ', 
          value: logoUrl, 
          onChange: (e) => setLogoUrl(e.target.value), 
          required: true 
        },


        { 
          type: 'text', 
          placeholder: 'Background Url ', 
          value: backgroundUrl, 
          onChange: (e) => setLogoUrl(e.target.value), 
          required: true 
        },
      ],
    },
  };
  
  const renderTabContent = () => {
    if (!activeTab || !tabsConfig[activeTab]) {
      return <p>Please select a valid tab</p>; // Handle case when activeTab is not yet set or invalid
    }
    
    const currentTab = tabsConfig[activeTab];
  
    if (!currentTab) return null;
    var users = verticalUsers.filter(item => item.vertical === currentTab.name)[0];
    if (!verticalUsers || !verticalUsers.length || !users?.users?.Items) {
      return <p>Not activated...</p>; // Handle loading state
    }
    // Calculate the index range for the current page
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;

    // Slice the users array to only show users for the current page
    const currentUsers = users.users.Items.slice(indexOfFirstUser, indexOfLastUser);
    const renderHeader = (headers) => (
      <div className="account-row">
        {headers.map((header, index) => (
          <p className="account-header" key={index}>{header}</p>
        ))}
      </div>
    );
  
    const renderAccounts = (accounts) => (
      accounts.map((account, index) => (
        <div className="account-row" key={`${account.BiauEmail?.S || 'no-email'}-${index}`}>
          <p className="account-item">{account.BiauEmail?.S || 'No Email'}</p>
          <p className="account-item">{account.Title?.S || 'No Title'}</p>
          <p className="account-item">{account.Type?.S || 'No Type'}</p>
          <p className="account-item">{account.Description?.S || 'No Description'}</p>
          <p className="account-item">
            {account.Color?.S ? (
              <>
                <span
                  style={{
                    display: 'inline-block',
                    width: '15px',
                    height: '15px',
                    backgroundColor: `#${account.Color.S}`,
                    marginRight: '8px',
                  }}
                ></span>
                {account.Color.S}
              </>
            ) : (
              'No Color'
            )}
          </p>
          <p className="account-item">{account.Date?.S || 'No Date'}</p>
          <p className="account-item">{account.Location?.S || 'No Location'}</p>
        </div>
      ))
    );
    
    
    const renderForm = (formFields, submitHandler, buttonText) => (
      <div className="form-card">
        <label className='label'>Add {activeTab}</label>
        <form className="aligned-form" onSubmit={submitHandler}>
          {formFields.map((field, index) => (
            <div className="aligned-form" key={index}>
              {field.type === 'select' ? (
                <select
                  className="form-input"
                  value={field.value}
                  onChange={field.onChange}
                  required={field.required}
                >
                  <option value="" disabled>{field.placeholder}</option>
                  {field.options.map((option, idx) => (
                    <option key={idx} value={option}>{option}</option>
                  ))}
                </select>
              ) : (
                <input
                  type={field.type}
                  className="form-input"
                  placeholder={field.placeholder}
                  value={field.value}
                  onChange={field.onChange}
                  required={field.required}
                />
              )}
            </div>
          ))}
          <div className="form-group">
            <button type="submit" onClick={saveVertical} className="form-button">Submit</button>
          </div>
        </form>
      </div>
    );
  
    const renderFileUpload = (fileHandler, uploadHandler) => (
      <div className="file-card">
        <div className='aligned-form'>
        <label className='label'>Add {activeTab}</label>
        <br/>
        <input type="file" accept=".csv" onChange={fileHandler} />
        <br/>
        <button  className="form-button" onClick={handleBulkUpload}>Upload</button>
        </div>
      </div>
    );
  
    const renderBottomSection = () => (
      <div className="bottom-div">
        <button onClick={toggleAddUsers} className="rounded-button">
          <span>{toggleForm ? '-' : '+'}</span>
        </button>
        <Pagination paginate={paginate} totalPages={totalPages} currentPage={currentPage} />
      </div>
    );

    return (
      <div>
        <div className="account-list">
          {renderHeader(currentTab.headers)}
          {renderAccounts(currentUsers)}
        </div>
  
        {toggleForm ? (
          toggleFormType ? (
            <div>
              <div className='toggleDiv'>
                <div className={`toggle-switch ${toggleFormType ? 'active' : ''}`} onClick={handleFormTypeToggle}>
                  <div className={`toggle-slider ${toggleFormType ? 'active' : ''}`}></div>
                </div>
              </div>
              {renderForm(currentTab.formFields, currentTab.formHandler, currentTab.submitHandler, "Add")}
            </div>
          ) : (
            <div>
              <div className='toggleDiv'>
                <div className={`toggle-switch ${toggleFormType ? 'active' : ''}`} onClick={handleFormTypeToggle}>
                  <div className={`toggle-slider ${toggleFormType ? 'active' : ''}`}></div>
                </div>
              </div>
              {renderFileUpload(handleFileChange, currentTab.bulkUploadHandler)}
            </div>
          )
        ) : null}
  
        {renderBottomSection()}
      </div>
    );
  };
  
  useEffect(() => {
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    setCurrentVerticalUsers(verticalUsers.slice(indexOfFirstUser, indexOfLastUser));
  }, [currentPage, verticalUsers, usersPerPage]);

  useEffect(() => {
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    setCurrentVerticalUsers(verticalUsers.slice(indexOfFirstUser, indexOfLastUser));
  }, [currentPage, verticalUsers, usersPerPage]);

 useEffect(() => {
  setTotalPages(Math.ceil(verticalUsers.length / usersPerPage)); // Ensure usersPerPage is defined here
}, [verticalUsers, usersPerPage]); 

  useEffect(() => {
    let orgName = localStorage.getItem('orgName');

    // Check if the logged-in user has access
    const LoggedInUserHasAcces = async () => {
      // Replace with real access control logic
      return true;
    };

    if (LoggedInUserHasAcces()) {
      const GetOrganisationInfo = async () => {
        setLoading(true);  // Start loading
        const url = 'https://755e4dp26rgnirtlxivngn7efq0wfwyq.lambda-url.eu-west-2.on.aws/';
        try {
          const requestData = {
            method: 'POST',
            headers: {
              'Content-Type': 'text/plain',
            },
            body: JSON.stringify({ orgName: orgName })
          };
          const response = await fetch(url, requestData);

          const data = await response.json();
          // Deserialization logic for organisation info
          const {OrganisationName ,Verticals, PaymentInfo, AdminEmail,CommunicationEmail,ShortHand } = data;
          // Update state based on the fetched data
          setOrgName(OrganisationName?.S || '');
          setShortHand(ShortHand?.S || '');
          setVerticals(Verticals?.L.map(vert => vert.S) || []);
          setAdminEmail(AdminEmail?.S || '');
          setCommunicationEmail(CommunicationEmail?.S || '');
          setPaymentInfo({
            amount: PaymentInfo?.M?.Amount?.N || '',
            periodicity: PaymentInfo?.M?.Periodicity?.S || 'Yearly',
          });
          console.log("Organization data deserialized:", data);
        } 
        catch (error) {
          
        } 
        try {
          if(shorthand == null){

          const requestData2 = {
            method: 'POST',
            headers: {
              'Content-Type': 'text/plain',
            },
            body: JSON.stringify({ shortHand: orgName.toUpperCase() })
          };
          const response2 = await fetch(url, requestData2);

          const data2 = await response2.json();
          // Deserialization logic for organisation info
          const {OrganisationName ,Verticals, PaymentInfo, AdminEmail,ShortHand } = data2;
          // Update state based on the fetched data
          setOrgName(OrganisationName?.S || '');
          setShortHand(ShortHand?.S || '');
          setVerticals(Verticals?.L.map(vert => vert.S) || []);
          setAdminEmail(AdminEmail?.S || '');
          setPaymentInfo({
            amount: PaymentInfo?.M?.Amount?.N || '',
            periodicity: PaymentInfo?.M?.Periodicity?.S || 'Yearly',
          });
          console.log("Organization data deserialized:", data2);
          }
        } catch (error) {
          console.error('Fetching error:', error);
          setErrorMessage('Failed to fetch organization info');
        }
        finally {
          setLoading(false);  // End loading
        }
      };

      // Initialize the data by fetching org info and vertical users
      const initializeData = async () => {
        await GetOrganisationInfo();
      };

      initializeData();
    }
  }, []);

  useEffect(() => { 
    if(activeTab == 'subscriptios'){
    }
   }, [activeTab]);

  useEffect(() => {
    if (verticals.length > 0) {
      const fetchUsersForVerticals = async () => {
        try {
          const url = 'https://snv5dvrxnzcdz7kwcxkkschrzm0whhyx.lambda-url.eu-west-2.on.aws/';
          let allUsers = [];
          
          for (const vertical of verticals) {
            const requestData = {
              method: 'POST',
              headers: {
                'Content-Type': 'text/plain',
              },
              body: JSON.stringify({ ShortHand: shorthand.toUpperCase(), VerticalName: vertical }),
            };
  
            // Make API request for each vertical
            const response = await fetch(url, requestData);
            if (response.ok) {
              const data = await response.json();
              allUsers.push({ vertical, users: data }); // Add the response to the list

            } else {
              console.error(`Failed to fetch users for vertical: ${vertical}`);
            }
          }
  
          // Store the collected users in state
          setVerticalUsers(allUsers);
          console.log('All users fetched for verticals:', allUsers);
        } catch (error) {
          console.error('Error fetching users for verticals:', error);
        }
      };
      fetchUsersForVerticals();
    }
  }, [verticals]); 
  
  return (
    <div className="ErpSystem">
      <header className="ErpSystem-header">
        <h1>{orgName} Company Management</h1>
        <div className="tab">
          <button className={`tablinks ${activeTab === 'subscriptions' ? 'active' : ''}`} onClick={() => setActiveTab('subscriptions')}>Subscriptions</button>
          <button className={`tablinks ${activeTab === 'accreditations' ? 'active' : ''}`} onClick={() => setActiveTab('accreditations')}>Accreditations</button>
          <button className={`tablinks ${activeTab === 'tickets' ? 'active' : ''}`} onClick={() => setActiveTab('tickets')}>Tickets</button>
        </div>
      </header>
      <div className="tabcontent">
        {renderTabContent()}
      </div>
    </div>
  );
}

export default Organisations;
