import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #1e1e1e, #222222);
  color: white;
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 20px;
  box-sizing: border-box;
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 20px;
  font-weight: bold;
`;

const Input = styled.input.attrs({ type: 'email' })`
  padding: 8px 15px;
  margin-top: 20px;
  border-radius: 5px;
  border: none !important;
  width: 300px;
  opacity: 0.5;
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  z-index: 2;
  flex-grow: 0 !important;

  &::placeholder {
    color: white;
    opacity: 0.7;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #0071e3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  z-index: 2;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  &:hover {
    opacity: 0.8;
  }
`;

const PurpleSquare = styled.div`
  width: 70%;
  height: 70%;
  background-color: #012345;
  position: absolute;
  box-shadow: 0 0 50px rgba(72, 0, 255, 0.5);
  z-index: 1;
  left: calc(15%);
  top: calc(15%);
`;

const ResetBiau = () => {
  const [email, setEmail] = useState('');

  const handleResetRequest = async () => {
    if (!email) {
        alert('Please enter your email address.');
        return;
    }

    try {
        const response = await fetch('https://x6z42a6ssllejls6w2jikk2fsy0fwrdk.lambda-url.eu-west-2.on.aws/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        if (response.ok) {
            alert('Reset request initiated. Please check your email.');
        } else {
            alert('Failed to initiate reset. Please try again.');
        }
    } catch (error) {
        console.error('Error initiating reset:', error);
        alert('An error occurred. Please try again.');
    }
  };

  return (
    <Container>
      <PurpleSquare />
      <Title style={{ color: 'white', zIndex: 2 }}>Are you sure you?</Title>
      <div style={{ color: 'white', zIndex: 2 }}>
        You are letting us know you want to reset the Biau for the following email:
      </div>
      <Input 
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button onClick={handleResetRequest}>Yes, Reset My Account</Button>
    </Container>
  );
};

export default ResetBiau;
