import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

function PrivateRoute({ children }) {
  const { isAuthenticated, getToken } = useAuth();
  const location = useLocation();
  const token = getToken();

  if (token) {
    // Uncomment to handle token expiration
    // if (decodedToken.exp * 1000 < Date.now()) {
    //   console.error('Token expired:', decodedToken.exp);
    //   localStorage.removeItem('token');
    //   return <Navigate to="/" />;
    // }
  }

  if (!isAuthenticated) {
    const currentPath = location.pathname; 
    localStorage.setItem('redirectPath', currentPath); 
    return <Navigate to="/" />;
  }

  return children;
}

export default PrivateRoute;
