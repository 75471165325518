import React from 'react';
import styled from 'styled-components';

const CenteredLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // Full height of the viewport
  background-color: black; // Background color of the page
`;

const StyledLink = styled.a`
  color: #3498db; // Color of the hyperlink
  text-decoration: none; // No underline
  font-size: 24px; // Font size of the hyperlink text

  &:hover {
    text-decoration: underline; // Underline on hover
  }
`;

function HomePage() {
  return (
    <CenteredLinkContainer>
      <StyledLink href="https://Biau.app/register/8d4681e0-065c-4bab-9e70-ba11e4b13346" target="_blank">Visit the Biau App</StyledLink>
    </CenteredLinkContainer>
  );
}

export default HomePage;


