import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const Button = styled.a`
  padding: 10px 20px;
  background-color: #0071e3;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.2rem;
  margin-top: 20px;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #005bb5;
  }
`;

const AppleBiometrics = () => {
  return (
    <Container>
      <h2>Apple Biometrics Setup Guide</h2>
      <p>
        For detailed instructions on setting up biometrics on your Apple device, please visit the official Apple support page.
      </p>
      <Button
        href="https://support.apple.com/en-us/102528"
        target="_blank"
        rel="noopener noreferrer"
      >
        Open Apple Support
      </Button>
    </Container>
  );
};

export default AppleBiometrics;
