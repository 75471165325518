import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM
import Modal from 'react-modal';
import styled from 'styled-components';

// Set the modal root element outside the main app root
const modalRoot = document.getElementById('modal-root');
const PurpleSquare = styled.div`
  width: 3000;
  height: 400px;
  background-color: #8b00ff;
  position: absolute;
  z-index: 0;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 50px rgba(139, 0, 255, 0.5);
`;

const Model = styled.div`
  width: 200px;
  height: 200px;
  position: absolute;
  z-index: 1000; /* 
`;

const ExpandSquare = styled.div`
@keyframes expand {
  0% {
    width: 0;
    height: 0;
  }
  100% {
    width: 3000px;
    height: 600px;
  }
}

.ExpandSquare {
 width: 3000;
  height: 400px;
  background-color: #8b00ff;
  position: absolute;
  z-index: 0;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 50px rgba(139, 0, 255, 0.5);
  left: calc(75% - 150px); /* Center horizontally */
   top: calc(25% - 150px);  /* Center vertically */
  animation: expand 2s forwards; /* Voeg de animatie toe en behoud de eindtoestand */
}
`;

function HelpModal({ isOpen, onRequestClose }) {
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setShowAnimation(true);
      }, 100); 
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Help Modal"
      className="Modal"
      overlayClassName="Overlay"
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <h2>Need Help?</h2>
      <p>To unlock the ERP system, please follow these steps:</p>
      <ol>
        <li>Open the BIAU app on your mobile device.</li>
        <li>Navigate to the QR code scanner within the app.</li>
        <li>Scan the QR code displayed on this page.</li>
        <li>The ERP system should unlock automatically.</li>
      </ol>
      <button onClick={onRequestClose}>Close</button>
      {showAnimation && <ExpandSquare />}
      <Model style={{ position: 'absolute', top: '50px', left: '50px' }} />
    </Modal>,
    document.getElementById('modal-root')
  );
}

export default HelpModal;