import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../Auth/AuthContext';

const DeletePolicy = () => {
  const navigate = useNavigate();
  const [verificationToken, setVerificationToken] = useState('');
  const { register } = useAuth();

  const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: black;
  position: relative;
  overflow: hidden;
`;

const PurpleSquare = styled.div`
  width: 3000px;
  height: 800px;
  background-color: #012345;
  position: absolute;
  box-shadow: 0 0 50px rgba(72, 0, 255, 0.5);
  z-index: 1;
  left: 50%; 
  top: 50%;
  transform: translate(-50%, -50%);
`;

const ScrollableContent = styled.div`
  width: 80%; // Adjust width as needed
  height: 800px;
  max-height: 70vh; // Adjust height to limit how much space it takes vertically
  color: white;
  text-align: left;
  padding: 20px;
  overflow-y: scroll; // Enable vertical scrolling
  border-radius: 10px; // Optional, for aesthetics
  position: relative; // Needed for z-index to take effect
  z-index: 2; // Ensure it's above the PurpleSquare
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); // Optional, for aesthetics
`;

return (
    <Container>
      <PurpleSquare />
      <h1 style={{ color: 'white', zIndex: 2, position: 'absolute', top: '10%' }}>BIAU Privacy Policy</h1>
      <ScrollableContent>
        <p>
          {/* Your privacy policy text here */}
          Delete Biau Account via the App.
        </p>
        {/* More paragraphs or structured content as needed */}
      </ScrollableContent>
    </Container>
  );
}


export default DeletePolicy;
