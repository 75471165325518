import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import AppleBiometrics from './AppleBiometrics';
import AndroidBiometrics from './AndroidBiometrics';

const BiauBiometrics = () => {
  const [platform, setPlatform] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setPlatform('apple');
    } else if (/android/i.test(userAgent)) {
      setPlatform('android');
    } else {
      setPlatform('unknown');
    }
  }, []);

  const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #1e1e1e, #222222);
    color: white;
    text-align: center;
    font-family: Arial, sans-serif;
    padding: 20px;
    box-sizing: border-box;
  `;

  const Title = styled.h1`
    font-size: 3rem;
    margin-bottom: 20px;
    font-weight: bold;
  `;

  const Description = styled.p`
    font-size: 1.2rem;
    margin-bottom: 40px;
    line-height: 1.6;
    max-width: 600px;
  `;

  const ContentContainer = styled.div`
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    max-width: 800px;
    margin-top: 20px;
  `;
  const PurpleSquare = styled.div`
    width: 70%;
    height: 70%;
    background-color: #012345;
    position: absolute;
    box-shadow: 0 0 50px rgba(72, 0, 255, 0.5);
    z-index: 1;
    left: calc(15% ); /* Center horizontally */
    top: calc(15% );  /* Center vertically */
  `;
  return (
    <Container>
      <PurpleSquare />
      <ContentContainer style={{ color: 'white', zIndex: 2 }}>
        {platform === 'apple' && <AppleBiometrics style={{ color: 'white', zIndex: 2 }} />}
        {platform === 'android' && <AndroidBiometrics  style={{ color: 'white', zIndex: 2 }} />}
        {platform === 'unknown' && (
          <p>Platform not recognized. Please use an Apple or Android device.</p>
        )}
      </ContentContainer>
    </Container>
  );
};

export default BiauBiometrics;
