import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const DownloadsPage = () => {
  const navigate = useNavigate();

  const googlePlayLink = "https://play.google.com/store/apps/details?id=biauapp.android";
  const appStoreLink = "https://apps.apple.com/app/biau/id6523429454"; // Replace with actual App Store link

  const googlePlayLogoUrl = process.env.PUBLIC_URL + "/google-play.png";
  const appStoreLogoUrl = process.env.PUBLIC_URL + "/apple-app-store.png";

  const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #1e1e1e, #222222);
    color: white;
    text-align: center;
    font-family: Arial, sans-serif;
    padding: 20px;
    box-sizing: border-box;
  `;

  const Title = styled.h1`
    font-size: 3rem;
    margin-bottom: 20px;
    font-weight: bold;
  `;

  const Description = styled.p`
    font-size: 1.2rem;
    margin-bottom: 40px;
    line-height: 1.6;
    max-width: 600px;
  `;

  const LogosContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 50%;
    margin-top: 40px;
  `;

  const LogoButton = styled.a`
    display: inline-block;
    width: 150px;
    height: auto;
    transition: transform 0.3s ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 15px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
  `;

  return (
    <Container>
      <Title>It Looks Like You Don't Have the App!</Title>
      <Description>
        It seems you’ve clicked on a link that requires our app to proceed. Unfortunately, the app isn’t installed on your device yet. Our app is designed to provide the best experience for accessing this content. To continue, you’ll need to download and install the app.
      </Description>
      <Description>
        Don’t worry! It’s quick and easy to get started. Just hit the download button below, and we’ll guide you through the process. Once the app is installed, come back and enjoy all the features we offer.
      </Description>

      <LogosContainer>
      <LogoButton href={appStoreLink} target="_blank" rel="noopener noreferrer">
          <img src={appStoreLogoUrl} alt="Download on the App Store" />
        </LogoButton>
        <LogoButton href={googlePlayLink} target="_blank" rel="noopener noreferrer">
          <img src={googlePlayLogoUrl} alt="Download on Google Play" />
        </LogoButton>
      </LogosContainer>
    </Container>
  );
};

export default DownloadsPage;
