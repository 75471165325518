import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const Button = styled.a`
  padding: 10px 20px;
  background-color: #3ddc84;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.2rem;
  margin-top: 20px;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #2cae68;
  }
`;

const AndroidBiometrics = () => {
  return (
    <Container>
      <h2>Android Biometrics Setup Guide</h2>
      <p>
        For detailed instructions on setting up biometrics on your Android device, please visit the official Pixel phone support page.
      </p>
      <Button
        href="https://support.google.com/pixelphone/answer/6285273?hl=en&sjid=13902338502612674987-EU"
        target="_blank"
        rel="noopener noreferrer"
      >
        Open Android Support
      </Button>
    </Container>
  );
};

export default AndroidBiometrics;
