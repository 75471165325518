import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../Auth/AuthContext';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const [verificationToken, setVerificationToken] = useState('');
  const { register } = useAuth();

  const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: black;
  position: relative;
  overflow: hidden;
`;

const PurpleSquare = styled.div`
  width: 3000px;
  height: 800px;
  background-color: #012345;
  position: absolute;
  box-shadow: 0 0 50px rgba(72, 0, 255, 0.5);
  z-index: 1;
  left: 50%; 
  top: 50%;
  transform: translate(-50%, -50%);
`;

const ScrollableContent = styled.div`
  width: 80%; // Adjust width as needed
  height: 800px;
  max-height: 70vh; // Adjust height to limit how much space it takes vertically
  color: white;
  text-align: left;
  padding: 20px;
  overflow-y: scroll; // Enable vertical scrolling
  border-radius: 10px; // Optional, for aesthetics
  position: relative; // Needed for z-index to take effect
  z-index: 2; // Ensure it's above the PurpleSquare
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); // Optional, for aesthetics
`;

return (
    <Container>
      <PurpleSquare />
      <h1 style={{ color: 'white', zIndex: 2, position: 'absolute', top: '10%' }}>BIAU Privacy Policy</h1>
      <ScrollableContent>
        <p>
          {/* Your privacy policy text here */}
          Privacy Statement of Biau B.V.
          <br/>

This Privacy Statement is applicable to all (mobile) websites, (mobile) applications, and/or (platform) applications of Biau B.V. ("Biau" or "we"). It outlines how we, as a controller, collect, use, share, and process data that identifies you ("personal data") in compliance with the General Data Protection Regulation ("GDPR"). This Privacy Statement does not cover third-party websites or services mentioned on our platform.
<br/>
We may update this Privacy Statement to reflect changes in applicable laws and regulations. We recommend regularly reviewing this document to stay informed of any updates.
<br/>

Who We Are
<br/>

Biau B.V.
Rapenburgerstraat 87-1
1011 VL Amsterdam
info@biau.com
0621256613
<br/>

Personal Data Storage and Usage
<br/>

Biau provides a secure platform accessible via (mobile) websites and applications for managing authorization and identification services. We typically collect your personal data directly from you when you engage with our platform, contact us, or create a Biau account to utilize our services.
<br/>

Biau Account
<br/>

To access our services, you need to create a Biau account, where we collect the following personal data:
<br/>

Name
Address
Email address
Age
Optionally, you may include your photo and specify your interests and preferences. Your account may also display your activities such as previous transactions or interactions within the platform.
<br/>

Platform Visits
<br/>

We process the IP address and the data of the device you use to access our platform, such as the device type or browser type. With your permission, we also process the location data of your device. This data helps in the delivery of services and allows us to offer location-based advertisements.
<br/>

We process personal data when you contact us to address your queries or complaints and to continually improve our platform.
<br/>

Marketing & Communication
<br/>

If you have a Biau account, we keep you informed about new services, platform functionalities, and updates through online newsletters, which you can opt out of at any time. If you've indicated specific interests in your account, we may also inform you about relevant advertisements or products, based on your permissions.
<br/>

Google Analytics
<br/>

We use Google Analytics in a privacy-conscious manner to optimize our website. We have a processing agreement with Google, your IP address is anonymized, and no data is shared with Google.
<br/>

Sharing of Personal Data
<br/>

We handle personal data confidentially and generally do not share it with third parties, except:
<br/>

When using third-party services for IT and delivery.
If required by law or a court order.
When logging in through social media, where the privacy policy of the social media platform will apply.
To prevent, detect, or mitigate fraud and security issues.
Data transfer outside the EU/EEA is conducted in compliance with legal requirements.
<br/>

Data Security
<br/>

We implement suitable technical and organizational measures to protect your personal data against unauthorized access and misuse.
<br/>

Data Retention
<br/>

Your personal data is retained no longer than necessary for its intended purposes, typically until you cancel your Biau account, after which it is promptly deleted.
<br/>

Your Rights
<br/>

You can manage your personal data through your account settings. You can also request access, correction, or deletion of your personal data by contacting us at the details provided above.
<br/>

Questions
<br/>
<br/>

For queries or complaints about our data handling practices, you can reach us using the contact details above. You also have the right to lodge a complaint with the Dutch data protection authority, the "Authority for Personal Data."
<br/>

Governing Law
<br/>

<br/>
This Privacy Statement is governed exclusively by Dutch law.
        </p>
        {/* More paragraphs or structured content as needed */}
      </ScrollableContent>
    </Container>
  );
}


export default PrivacyPolicy;
